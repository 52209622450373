<template>
    <div class="top brand">
        <div class="typeSelect">
            <el-button
                v-for="(item, index) in collectType"
                :type="item.key === type ? 'warning' : 'info'"
                :key="index"
                plain
                size="small"
                @click="chooseType(item.key)"
                >{{ $t(item.name) }}</el-button
            >
        </div>
        <div class="flex1"></div>
        <el-input
            size="mini"
            :placeholder="$t('zai-jie-guo-zhong-sou-suo')"
            class="search"
            v-model="search"
            clearable
            @change="submitSearch"
            @clear="submitSearch"
        >
            <div slot="append" @click="submitSearch" class="iconfont">
                &#xe603;
            </div>
        </el-input>

        <div class="right-btn">
            <slot name="right"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'topSelect',
    props: {
        searchKey: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            search: ''
        };
    },
    watch: {
        searchKey() {
            this.$nextTick(() => {
                if (this.search !== this.searchKey) {
                    this.search = this.searchKey;
                }
            });
        },
        search() {
            this.$nextTick(() => {
                if (this.search !== this.searchKey) {
                    this.$emit('update:searchKey', this.search);
                }
            });
        }
    },
    methods: {
        submitSearch() {
            this.$nextTick(() => {
                this.$emit('submitSearch');
            });
        },
        chooseType(key) {
            this.$emit('update:type', key);
        }
    },
    computed: {
        collectType() {
            return [
                {
                    key: '0',
                    name: 'wei-kai-shi'
                },
                {
                    key: '1',
                    name: 'yi-jie-shu'
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.top {
    display: flex;
    background-color: #fff;
    height: 56px;
    align-items: center;
    padding: 0 15px;

    .el-button {
        display: block;
    }
    .typeSelect {
			display: flex;
			align-items: center;
        .el-button--warning.is-plain {
            background-color: transparent;
            border-color: transparent;
            &:hover,
            &:checked,
            &:focus {
                color: $--color-warning;
            }
        }
    }

    .el-button--info.is-plain {
        background-color: transparent;
        border-color: transparent;
        &:hover,
        &:checked {
            // background-color: #f3f4f5;
            color: $--color-warning;
        }
        &:focus {
            color: $--color-info;
        }
    }
}

.search {
    height: 32px;
    display: flex;
    width: auto;
    margin-left: 30px;
}
</style>
