<template>
    <div class="activity">
        <activity-top :type.sync="isEnd" :searchKey.sync="searchKey" @submitSearch="submitSearch"> </activity-top>
        <div class="el-main" v-loading="loading">
            <div class="mainList" ref="list">
                <Row class="row" :info="item.information" v-for="item in showList" :key="item.id" userActivity />
            </div>
            <empty-page v-if="showListEmpty">
                <span slot="text">{{$t('ni-huan-mei-you-jia-ru-huo-dongo')}}</span>
            </empty-page>
        </div>
        <el-footer>
            <el-pagination
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </el-footer>
    </div>
</template>
<script>
import pageableList from '../../mixins/pageableList';
import ActivityTop from '../../components/activity/Top';
import Row from '../../components/news/Row.vue';
import { mapState } from 'vuex';
export default {
    name: 'Activity',
    mixins: [pageableList],
    data() {
        return {
            url: '/eventRegistration/all',
            formType: { body: 'json' },
            isEnd: '0'
        };
    },
    computed: {
        ...mapState(['userInfo']),
        listQuery() {
            return {
                query: { userId: this.userInfo.id, del: false }
            };
        },
        showList() {
            return [...this.list].filter(item => {
                if (Number(this.isEnd)) {
                    return item.information && item.information.isEnd;
                } else {
                    return item.information && !item.information.isEnd;
                }
            });
        },
        showListEmpty() {
            if (this.empty) {
                return true;
            } else if (!this.loading && this.showList.length == 0) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        this.getData();
    },
    components: {
        ActivityTop,
        Row
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/pagebleTable';
@import '../../styles/variables';

.activity {
    background-color: transparent !important;
}
.el-main {
    margin: 20px 0 0;
    // min-height: calc(max(100%, 1000px)- 136px);
    @include max-height(136px, $total: 100%);
    //  min-height: max(calc(100% - 136px), 100px);
    overflow: hidden;
}
.row {
    margin-bottom: 20px;
}
</style>
