<template>
    <router-link class="news-info" :to="routerPath">
        <el-card :body-style="{ padding: '0px' }">
            <div class="box">
                <div class="top">
                    <auto-img class="logoImg" :src="info.img" mul="71.4%"></auto-img>
                </div>
                <div class="info">
                    <div class="name">
                        <el-tag v-if="isList && info.top" size="mini" effect="dark">{{ $t('zhi-ding') }}</el-tag>
                        <!-- {{ info.title }} -->
                        <span v-html="getImport(info.title)"></span>
                    </div>
                    <template v-if="isActivity">
                        <div class="info-item" style="margin-top: 20px;">
                            <div class="info-name">{{ $t('shi-jian') }}：</div>
                            <div class="info-val">{{ info.date }}</div>
                        </div>
                        <div class="info-item">
                            <div class="info-name">{{ $t('di-zhi') }}：</div>
                            <div class="info-val">{{ info.address }}</div>
                        </div>
                        <div class="info-item">
                            <div class="info-name">{{ $t('chang-ci') }}：</div>
                            <div class="info-val">
                                <el-tag
                                    size="mini"
                                    effect="plain"
                                    type="info"
                                    v-for="(item, index) in info.session"
                                    :key="index"
                                    >{{ item }}</el-tag
                                >
                            </div>
                        </div>

                        <el-button
                            :type="isEnd ? 'info' : 'warning'"
                            :disabled="isEnd"
                            :readonly="userActivity"
                            class="btn"
                            size="medium"
                            >{{
                                isEnd
                                    ? $t('bao-ming-jie-shu')
                                    : userActivity
                                    ? $t('bao-ming-zhong')
                                    : $t('li-ji-bao-ming')
                            }}</el-button
                        >
                    </template>
                    <template v-else>
                        <div class="sub">
                            {{ info.introduction }}
                        </div>

                        <div class="tip"></div>

                        <div class="time">
                            <span>{{ info.createdAt }}</span>
                            <img src="../../assets/icon_more.png" alt="" />
                        </div>
                    </template>
                </div>
            </div>
        </el-card>
    </router-link>
</template>
<script>
export default {
    name: 'Row',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        userActivity: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ''
        },
        isList: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isActivity() {
            return this.info.type === 'INDUSTRY_ACTIVITIES';
        },
        isEnd() {
            return this.info.isEnd || false;
        },
        type() {
            return this.$route.query.type || '';
        },
        routerPath() {
            let path = '';
            if (this.userActivity) {
                path = '/mineActivityDetail?informationId=' + this.info.id;
            } else {
                path = '/NewsDetail?id=' + this.info.id;
            }

            if (this.type) {
                path += '&type=' + this.type;
            }
            return path;
        }
    },
    methods: {
        goDetail() {
            if (this.userActivity) {
                this.$router.push('/mineActivityDetail?informationId=' + this.info.id);
            } else {
                this.$router.push('/NewsDetail?id=' + this.info.id);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.news-info {
    display: block;
}
.box {
    display: flex;
}
.el-card {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
    position: relative;
    border-width: 0;

    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.08);
        .name {
            color: $--color-warning;
        }
    }
}
.new-img {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 30px;
}
.top {
    flex-shrink: 0;
    width: 3.74rem;
}

.name {
    font-size: 22px;
    color: $--color-text-regular;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
        vertical-align: middle;
    }

    .el-tag {
        margin-right: 4px;
        background-color: #ef8207;
        border-color: #ef8207;
        padding: 0 2px;
        font-size: 14px;
    }
}

.sub {
    font-size: 14px;
    color: #878d99;
    line-height: 24px;
    margin-top: 0.1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.el-card {
    cursor: pointer;
}

.info {
    padding: 0.3rem 0.3rem 0.18rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.tip {
    flex-grow: 1;
}
.time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.18rem 0 0;
    border-top: 1px solid #f2f3f5;

    img {
        width: 20px;
        height: 20px;
        display: block;
    }

    span {
        font-size: 13px;
        color: rgba(172, 179, 191, 1);
        line-height: 24px;
    }
}

.info-item {
    display: flex;
    align-items: center;

    .info-name {
        font-size: 13px;
        color: rgba(188, 193, 204, 1);
        line-height: 33px;
        margin-right: 20px;
    }

    .info-val {
        font-size: 12px;
        color: rgba(86, 91, 102, 1);
        line-height: 22px;

        .el-tag {
            margin-right: 10px;
            border-radius: 0px;
        }
    }
}

.btn {
    position: absolute;
    min-width: 145px;
    right: 30px;
    bottom: 20px;
    line-height: 24px;
    padding: 6px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
